.emptystate {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .center {
        padding-top: 96px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .iconCircle {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            color: #FF0030;
            padding: 16px;
            gap: 10px;
            width: 56px;
            height: 56px;
            background: #EFEFEF;
            border-radius: 188px;

        }
    }
}