.container {
    display: flex;
    overflow-x: hidden;

    .header {
        flex-shrink: 0;
        border-radius: 8px;
        background: var(--color-neutral-grey-100, #F7F7F7);
        width: 100%;
        padding: 24px;
        display: flex;

        flex-wrap: wrap;
        justify-content: space-between;
        gap: 22px;

        &>div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            width: 45%;

            &:nth-child(even) {
                align-items: flex-end;
            }
        }


    }

    // .footer {
    //     display: inline-flex;
    //     padding: 8px 40px;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     gap: 10px;
    //     width: 100%;
    //     // margin: 12px;
    //     border-radius: 8px;
    //     background: var(--color-neutral-grey-100, #F7F7F7);
    // }

    .left {
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        flex: 75%;
    }

    .right {
        // width: 23%;
        flex: 20%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid var(--color-neutral-grey-500, #E2E2E2);
        background: var(--color-neutral-white, #FFF);
        padding: 0 24px;

        // width: 252px;
        .rightHeader {
            align-items: center;
            display: flex;
            gap: 10px;
            border-bottom: 1px solid var(--color-neutral-grey-500, #E2E2E2);
            background-color: #FFF;
            height: fit-content;
            width: 100%;
            padding: 16px 4px;
            // margin: 0 24px;
        }

        .rightBody {
            padding: 30px 0;
        }

    }
}