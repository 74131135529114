.btns {
    color: red;

    button {

        // display: none;
        svg {
            fill: #4F4F4F;
        }
    }
}

.containter {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .input {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.history {
    .list {

        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;
    }

    .item {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.box {
    display: flex;
    padding: 16px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
background: var(--color-neutral-grey-300, #EFEFEF);
margin: 10px 0;
}