.btn {
    display: flex;
    gap: 10px;
    min-height: 35px;
    // max-height: 60px;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 10px 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: fit-content;
    text-align: center;
    -webkit-transition-duration: 0.4s;
    /* Safari */
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    position: relative;
    p{
        white-space: nowrap !important;
    }
    @media screen and (max-width:576px) {
        padding: 6px 10px;
        
    }
 &:hover{
        background-color: #570909;
    }   
}

.disabled {
    opacity: .5;
}

.shadow {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);

    &:hover {
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.6);
    }

    &::after {

        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.9);
    }
}

.buttonGroup {
    display: flex;
    border-radius: 8px !important;
    background-color: #F7F7F7;
    width: 132px;
    font-size: 25px; 
    @media screen and (max-width:600px) {
        position: absolute;
        z-index: 100;
        right: 40px;
        top: 120px;
    }
}