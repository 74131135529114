.clickaway {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.5);
    /* BG Blur */

    backdrop-filter: blur(3px);
    z-index: 5;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 7;
    background: #FFFFFF;
    border-radius: 8px;
    width: min(90%, 500px);
    padding: 24px 40px;
    max-height: 700px;

    overflow-y: auto;


   &::-webkit-scrollbar {
        width: 8px;
        // border-radius: ;
    }

    /* Track */
   &::-webkit-scrollbar-track {
        background: white;
        width: 5px;
        border-radius: 8px;
    }

    /* Handle */
   &::-webkit-scrollbar-thumb {
        background: lightgray;
        width: 5px;
        border-radius: 24px;
    }

    .title {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 14px;

    }

    @media screen and (max-width: 576px) {

        padding: 12px 24px;
    }
}