.body {
    table {
        tr {
            :nth-child(1) {
                border-left: none !important;
            }

            td {
                border-left: 2px #efefef dashed;
            }

        }

        select,
        input {
            border-radius: 4px;
            padding: 5px;
            outline: none;

        }
    }
}