.burger {
    display: none;

    @media screen and (max-width:992px) {
        display: block;
        position: fixed;
        top: 23.5px;
        left: 10px;

    }
}

.clickaway {
    display: none;

    @media screen and (max-width:992px) {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        //    background-color: red;
        z-index: 10;

    }
}

.sidebar {
    width: 276px;
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    // position: fixed;
    // ::-webkit-scrollbar-thumb:1px,
    // background-color: #FF0030;
    &::-webkit-scrollbar {
        width: 4px;
        background-color: white;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;
        background-color: grey;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        width: 5px;
        background-color: white;
    }


    @media screen and (max-width:992px) {
        position: absolute;
        z-index: 1000;
        // left: 3000px;
        // display: none;
        width: 300px;
    }

    .bars {
        // height: 48px;
        // width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        cursor: pointer;
        width: 100%;
        // @media screen and (max-width:1103px) {
        //     display: none;
        // }

        .text {
            display: flex;
            gap: 14px;
            height: 58px;
            align-items: center;
            width: 100%;
            padding-left: 18px;
            word-wrap: normal;
            position: relative;
            font-size: 15px;

            &:hover {
                background-color: #EFEFEF;
                color: #FF0030;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;
            }

            .chevron {
                position: absolute;
                right: 10px;
                top: 40%;
                font-weight: 800;
            }

        }

        .active {
            background-color: #EFEFEF;
            color: #FF0030;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: capitalize;

            svg {
                // display: none;
                color: #FF0030 !important;
                // background: #FF0030 !important;
                fill: #FF0030 !important;

            }

        }
    }


}