.body {
    // background-color: ;
    // position: ÷h;
    z-index: 10000;
    position: relative;
    width: 100%;

    // over
     .container {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: white;
        overflow-y: auto;
        height: fit-content;
        padding: 0 16px;
        width: 100%;
        z-index: 10000 !important;
        position: sticky !important;
        top: 0;
        border: .2px solid #FEFEFE;

        /* Dropdown Shadow */
        box-shadow: 4px 4px 10px 4px rgba(29, 29, 31, 0.16);
        &>div{
            padding: 12px 0;
            cursor: pointer;
        }

    }
}