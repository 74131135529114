.container {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;

    .next {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .step {
        display: flex;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        height: 30px;
        width: 30px;
        color: #4F4F4F;
        background-color: #EFEFEF;
        cursor: pointer;
        transition: color 1s, background-color 1s;
    }

    .active {
        color: white !important;
        background-color: var(--color-primary-red, #FF0030) !important;
    }

    .done {
        color: white !important;
        background-color: #27AE60 !important;
    }

    .line {
        width: 70px;
        height: 4px;
        border-radius: 12px;
        background-color: #EFEFEF;
    }

    .verticalLine {
        height: 70px;
        width: 4px;
        border-radius: 12px;
        background-color: #EFEFEF;
    }

    .vertical {
        flex-direction: column;
    }


}

.vContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-direction: column;
    position: relative;

    .next {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 10%;
        
        flex-direction: column;
    }

    .stepContainer {
        position: relative;
        display: flex;
        gap: 16px;
        width: 100%;

    }
    .content{
        align-self: flex-end;
        width: 95%;
        position: absolute;
    left: 15%;
    // top: 30px;
    }

    .step {
        display: flex;
        padding: 4px;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        height: 30px;
        width: 30px;
        color: #4F4F4F;
        background-color: #EFEFEF;
        cursor: pointer;
        transition: color 1s, background-color 1s;
    }

    .active {
        color: white !important;
        background-color: var(--color-primary-red, #FF0030) !important;
    }

    .done {
        color: white !important;
        // background-color: #27AE60 !important;
    }

    .verticalLine {
        height: 70px;
        width: 4px;
        border-radius: 12px;
        background-color: #EFEFEF;
    }



}