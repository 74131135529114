.formContainer {
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    overflow-x: hidden;
    // max-height: 80vh;



    header {
        width: 100px;

    }

    button[type=submit] {
        margin: 25px;

    }

}