.textstyles {
    font-family: 'Inter';
    font-style: normal;
    color: inherit;
    display: flex;
    align-items: center;


}

.flextext {
    display: flex;
    // height: 56px;
    align-items: center;
    // gap: 16px;
    justify-content: space-between;
    align-self: stretch;
    width: 100%;
}