.container {
    width: 95% !important;
    margin-bottom: 150px 0;
    padding-bottom: 150px 0;
    margin: auto;
    height: fit-content;
    display: block;

    // background-color: red;
    overflow-y: scroll;

    .headerRow {
        box-sizing: border-box;

        // position: absolute;
        // width: 1086px;
        height: 50px;
        // left: 298px;
        // top: 128px;

        /* Color/Neutral/White */

        background: #FFFFFF;
        /* Color/Neutral/Grey.500 */

        border-bottom: 1px dashed #E2E2E2;
    }

    .head {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        /* identical to box height, or 150% */

        // text-align: left;
        text-transform: capitalize;

        /* Color/Neutral/Grey.900 */

        color: #4F4F4F;
    }

    .row {
        border-bottom: 3px #000000 solid;

        .cell {
            /* Type/16 Regular */

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            // text-align: left;

            /* Color/Neutral/Black */

            color: #000000;

            position: relative;
            max-height: 50px;

        }

        &:last-child {

            // background-color: green !important;
            .goLow {
                top: -45px;

            }
        }


    }

    .action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        position: relative;
        // z-index: ;
        /* Color/Neutral/Grey.300 */
        cursor: pointer;
        background: #EFEFEF;
        border-radius: 100px;
    }

    tr {
        td {

            // display: inline÷;
            border-bottom: 2px #E2E2E2 dashed;
            box-sizing: border-box;
        }
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
        width: 100%;
    }
}

.page {
    margin-top: 50px;
    width: 100%;
    //    background-color: #000000;
    display: flex;
    justify-content: center;
}