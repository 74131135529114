.reqInvTran{
    table {
        tr {
            :nth-child(1) {
                border-left: none !important;
            }

            td {
                border-left: 2px #efefef dashed;
            }

        }

        select,
        input {
            border-radius: 4px;
            padding: 5px;
            outline: none;
            // .no-spinners {
                -moz-appearance: textfield;
            //  }
             
             &::-webkit-outer-spin-button,
             &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
             }

        }
    }

}