.container {
    width: 100%;
    display: flex;
    // width: 500px;
    // height: 56px;
    padding: 5px;
    // flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--color-neutral-grey-300, #EFEFEF);
    gap: 8px;

    .tab {
        display: flex;
        height: calc(56px - 10px);
        // padding: 0px 40px 8px 40px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex: 1;border-radius: 8px;
        cursor: pointer;
    }
    .active{
        background: var(--color-neutral-white, #FFF);
    }
}