.search {
    // border: red 1px dashed;
    // padding: 20px;
    position: relative;

    .box {
        position: absolute;
        width: 250px;
        right: 50px;
        top: -50px;
    }
}