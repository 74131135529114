.actionmodal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px;

    position: absolute;
    width: 170px;
    // height: 128px;
    left: -125px;
    top: 45px;
    z-index: 10000 !important;
    /* Color/Neutral/White */

    background: #FFFFFF;
    /* Dropdown Shadow */

    box-shadow: 0px 4px 10px rgba(29, 29, 31, 0.16);
    border-radius: 8px;


    button {
        // display: none;
        font-weight: 400;
        svg{
            // display: none;
            font-weight: 300;
        }
        justify-content: left;
    }
}

.clickaway {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    // display: none;
    top: 0;
    cursor: auto;
    z-index: 10;
}