.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;

    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;
        color: #4F4F4F;
    }

    .inputBox {
        width: 100%;
        height: auto;
        padding: 14px;
        background: #EFEFEF;
        border-radius: 8px;
        // padding: 0 16px;÷
        justify-content: stretch;
        display: flex;
        // border: 1px solid red;
        align-items: center !important;
        // gap: 8px;
        padding-left: 10px;
        // background-color: #000000;

        input,
        select,
        textarea {
            width: 100%;
            background-color: inherit;
            outline: none;
            border: none;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            margin: 0 16px;

            &::placeholder {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #4F4F4F;
            }
        }
    }
}

.fileInput {
    display: flex;
    width: min(459px,100%);
    min-height: 100px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px dashed var(--color-primary-red, #FF0030);
    background: var(--color-neutral-white, #FFF);
    cursor: pointer;
    color: #FF0030;
    img{
        width: 100%;
        max-width: 100% !important;
        max-height: 200px;
    }
}
.editInput{
    display: flex;   width: 100%;
    height: auto;
    padding: 14px;
    background: inherit;
    border-radius: 8px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    // gap: 24px;
    padding-left: 10px;
    label{
        display: block;
        width: 30%;
    }
    div{
        display: flex;
        width: 70%;
        justify-content: space-between;
        label{
            width: 30%;
            text-align: center;
        }
    }
    input{
        width: 100%;
        background-color: inherit;
        outline: none;
        border: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        margin: 0 16px;
        border-radius: 8px;
        padding:5px 10px;

        &::placeholder {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #4F4F4F;

        }
        &:focus{
            border: 1px #FF0030 solid;
            background-color: #FFF;

        }
    }
}