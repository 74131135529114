.layout {
    width: 100vw;
    height: 100vh;

    body {
        display: flex;
        height: calc(100vh - 64px);
        width: 100%;
        position: fixed;
        color: black;

     
        main {
            width: 100%;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width:8px;
                background-color: white;
            }
        
            &::-webkit-scrollbar-thumb {
                width: 6px;
                background-color: grey;
                border-radius: 7px;
            }
        
            &::-webkit-scrollbar-track {
                width: 8px;
                background-color: white;
            }
        
    

            @media screen and (max-width:1103px) {
                width: calc(100% - 300px);
            }

            @media screen and (max-width:1103px) {
                width: 100%;
            }
        }
    }
}