.header {
    height: 64px;
    border-bottom: 1px solid #E2E2E2;
    display: flex;
    padding: 0 40px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .burger {
        display: none;

        @media screen and (max-width:992px) {
            display: block;
            position: absolute;
            left: 10px;
            cursor: pointer;
        }
    }

    .rightheader {
        display: flex;
        gap: 20px;

        .iconcircle {
            width: 36px;
            height: 36px;
            background: #E2E2E2;
            border-radius: 40px;
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: center;
        }

        .right {
            display: flex;
            align-items: center;
            gap: 5px;
            position: relative;

            .profile {
                position: absolute;
                width: 230px;
                height: 264px;
                top: 60px;
                right: 0;
                padding: 8px 0px 8px 0px;
                border-radius: 8px;
                box-shadow: 0px 2px 16px 0px #1D1D1F3D;
                background-color: #FFFFFF;
                z-index: 100;
                // cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .innerProfile {
                    width: 100%;
                    height: 80px;
                    padding: 16px 0px 16px 16px;
                    border-radius: 8px;
                    gap: 16px;
                    display: flex;
                    align-items: center;
                    .iconcircle{
                        background-color: #27AE60;
                        height: 100%;
                        aspect-ratio: 1/1;
                        width: 48px;
                    }

                    .innerRightProfile {
                        display: flex;
                        gap: 12px;
                        flex-direction: column;
                    }

                    .admin {
                        display: flex;
                        justify-content: center;
                        padding: 2px 4px;
                        width: fit-content;
                        align-items: center;
                        border-radius: 4px;
                        background: var(--color-neutral-grey-300, #EFEFEF);
                    }
                }

                button {
                    text-align: left;
                    display: flex;
                    gap: 16px;
                    padding: 10px 0;
                    padding-left: 24px;
                    height: 40px;
                    align-items: center;
                }
            }

            .clickAway {
                height: 100vh;
                width: 100vw;
                z-index: 10;
                // background-color: red;
                position: fixed;
                top: 0;
                left: 0;
            }
        }
    }
}